<template>
  <node-view-wrapper class="code-block">
    <div class="code-block__header">
      <btn-card icon="fas fa-copy" type="primary" @click="copy">
        <template slot="desc">
          <p>copy</p>
        </template>
      </btn-card>
      <select contenteditable="false" v-model="selectedLanguage" class="form-select">
        <option :value="null">
          auto
        </option>
        <option disabled>
          —
        </option>
        <option v-for="(language, index) in languages" :value="language" :key="index">
          {{ language }}
        </option>
      </select>
    </div>
    <pre><node-view-content as="code" ref="code" /></pre>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2';
import clipboard from "@/utils/clipboard";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
  },

  props: nodeViewProps,

  data() {
    return {
      languages: this.extension.options.lowlight.listLanguages(),
    }
  },

  computed: {
    selectedLanguage: {
      get() {
        return this.node.attrs.language
      },
      set(language) {
        this.updateAttributes({ language })
      },
    },
  },
  methods: {
    copy() {
      clipboard.copy(this.$refs.code.$el.innerText);
    }
  }
}
</script>

<style lang="scss">

</style>