<template>
  <div class="md-editor">
    <div class="md-editor__settings">
      <div class="md-editor__settings-group">
        <!-- <div class="md-editor__settings-back">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div class="md-editor__settings-file">
          <input v-model="file.filename">
          <span>Créé le 27 fév. 2021 ° Mathis Figuet</span>
        </div> -->
        <div class="md-editor__settings-options">
          <i class="fas fa-star" :class="{ 'active': isAlreadyAFav }" @click="toggleFav"></i>
        </div>
      </div>
      <div class="md-editor__settings-group" v-if="status === 'connected'">
        <div class="md-editor__settings-connected" v-if="numberConnectedUsers > 1" @click="userConnectedModalVisible = !userConnectedModalVisible">
          <i class="fas fa-users"></i>
          <span>
            {{numberConnectedUsers}}
          </span>
        </div>
        <modal :show.sync="userConnectedModalVisible"
                :centered="false"
                :show-close="true">
          <template>
            <h1>List of connected users</h1>
            <ul>
              <li v-for="(user, key) in editor.storage.collaborationCursor.users" :key="key">{{user.name}}</li>
            </ul>
          </template>
        </modal>

        <btn-card icon="fas fa-link" type="primary" @click="copyLink">
          <template slot="desc">
            <p>Share</p>
          </template>
        </btn-card>
      </div>
      <div class="md-editor__settings-group" v-else>
        <span>Offline</span>
      </div>
    </div>
    <div class="md-editor__tools">
      <div class="md-editor__tools-group">
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
          <i class="fas fa-bold" v-b-tooltip.hover.top="'Bold'"></i>
        </div>
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
          <i class="fas fa-italic" v-b-tooltip.hover.top="'Italic'"></i>
        </div>
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('underline') }" @click="editor.chain().focus().toggleUnderline().run()">
          <i class="fas fa-underline" v-b-tooltip.hover.top="'Underline'"></i>
        </div>
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()">
          <i class="fas fa-strikethrough" v-b-tooltip.hover.top="'Strike'"></i>
        </div>
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('codeBlock') }" @click="editor.chain().focus().toggleCodeBlock().run()">
          <i class="fas fa-code" v-b-tooltip.hover.top="'CodeBlock'"></i>
        </div>
      </div>

      <div class="md-editor__tools-group">
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('orderedList') }" @click="editor.chain().focus().toggleOrderedList().run()">
          <i class="fas fa-list-ol" v-b-tooltip.hover.top="'OrderedList'"></i>
        </div>
        <div class="md-editor__tools-tool" :class="{ 'active': editor.isActive('bulletList') }" @click="editor.chain().focus().toggleBulletList().run()">
          <i class="fas fa-list-ul" v-b-tooltip.hover.top="'BulletList'"></i>
        </div>
        <div class="md-editor__tools-tool" @click="editor.commands.setHorizontalRule()">
          <i class="fas fa-minus" v-b-tooltip.hover.top="'Horizontal'"></i>
        </div>
      </div>

      <div class="md-editor__tools-group">
        <div class="md-editor__tools-tool" @click="editor.commands.undo()">
          <i class="fas fa-undo" v-b-tooltip.hover.top="'Undo'"></i>
        </div>
        <div class="md-editor__tools-tool" @click="editor.commands.redo()">
          <i class="fas fa-redo" v-b-tooltip.hover.top="'Redo'"></i>
        </div>
      </div>

      <div class="md-editor__tools-group">
        <div class="md-editor__tools-tool" @click="insertMarkdown">
          <i class="fas fa-bookmark" v-b-tooltip.hover.top="'Insert Markdown'"></i>
        </div>
      </div>

      <div class="md-editor__tools-group">
        <div class="md-editor__tools-tool" @click="downloadAsPDF">
          <i class="fas fa-file-pdf" v-b-tooltip.hover.top="'Download as PDF'"></i>
        </div>
        <div class="md-editor__tools-tool" @click="downloadAsMarkdown">
          <i class="fas fa-arrow-alt-circle-down" v-b-tooltip.hover.top="'Download as Markdown'"></i>
        </div>
      </div>
    </div>
    <div class="md-editor__wrapper" :spellcheck="false" ref="editor">
      <editor-content :editor="editor" />
    </div>
  </div>
</template>
<script>
  import { Editor, EditorContent, VueNodeViewRenderer  } from '@tiptap/vue-2';
  import Highlight from '@tiptap/extension-highlight';
  import Typography from '@tiptap/extension-typography';

  import StarterKit from '@tiptap/starter-kit';
  import Underline from '@tiptap/extension-underline'
  import Document from '@tiptap/extension-document';
  import Paragraph from '@tiptap/extension-paragraph';
  import Text from '@tiptap/extension-text';
  import Image from '@tiptap/extension-image'
  import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
  import CodeBlockComponent from "@/components/Editor/Blocks/CodeBlockComponent";
  import lowlight from 'lowlight';
  import CharacterCount from '@tiptap/extension-character-count';

  // Paste markdown support
  import { markPasteRule } from '@tiptap/core';
  const CustomMarkdown = Typography.extend({
    addPasteRules() {
      return [
        markPasteRule({
          find: /#/g,
          type: "code"
        })
      ]
    }
  });

  // Web Socket
  import * as Y from 'yjs';
  import { WebsocketProvider } from 'y-websocket';
  import { IndexeddbPersistence } from 'y-indexeddb';
  import Collaboration from '@tiptap/extension-collaboration';
  import CollaborationCursor from '@tiptap/extension-collaboration-cursor';

  import Modal from '@/components/Modal';

  import { marked } from "marked";
  import TurndownService from "turndown";
  import { saveAs } from 'file-saver';

  import clipboard from "@/utils/clipboard";

  export default {
    components: {
      EditorContent,
      Modal
    },
    data() {
      return {
        file: {},
        editor: null,
        provider: null,
        indexdb: null,
        room: null,
        status: null,
        userConnectedModalVisible: false,
        pastMarkdownModalVisible: false,
        mdPaste: ""
      }
    },
    created() {
      this.file = {
        id: this.$route.params.id,
        filename: 'Document sans titre',
        content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
        visibility: 'public',
      }

      this.$store.commit("registerFile", this.file.id);

      this.room = this.file.id;

      const ydoc = new Y.Doc();
      
      this.provider = new WebsocketProvider('wss://think-together.mathis-figuet.com', this.room, ydoc);
      this.provider.on('status', event => {
        this.status = event.status
      });

      this.indexdb = new IndexeddbPersistence(this.room, ydoc);

      this.editor = new Editor({
        extensions: [
          StarterKit,
          Underline,
          Highlight,
          Typography,
          Document,
          Paragraph,
          Text,
          Image,
          CodeBlockLowlight
            .extend({
              addNodeView() {
                return VueNodeViewRenderer(CodeBlockComponent)
              },
            })
            .configure({ lowlight }),
          Collaboration.configure({
            document: ydoc,
          }),
          CollaborationCursor.configure({
            provider: this.provider,
            user: this.$store.getters.user,
          }),
          CharacterCount.configure({
            limit: 10000,
          }),
        ],
      });
    },
    mounted() {
      this.$refs.editor.addEventListener("drop", (event) => {
        event.preventDefault();
        let data = event.dataTransfer.files;
        for (let file of data) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.insertImage(e.target.result);
          }
        }
      })
    },
    beforeDestroy() {
      this.editor.destroy();
      this.provider.destroy();
    },
    computed: {
      userProps() {
        return this.$store.getters.user;
      },
      enableRTL() {
        return this.$route.query.enableRTL;
      },
      numberConnectedUsers() {
        return this.editor.storage.collaborationCursor.users.length;
      },
      isAlreadyAFav() {
        return this.$store.state.file.favorites.includes(this.file.id);
      }
    },
    watch: {
      userProps (newUser, oldUser) {
        this.editor.commands.updateUser(newUser);
      }
    },
    methods: {
      insertImage(base64) {
        this.editor.chain().focus().setImage({ src: base64 }).run();
      },
      async insertMarkdown() {
        // TODO update
        const text = await navigator.clipboard.readText();
        const htmlContent = marked.parse(text);
        this.editor.commands.insertContent(htmlContent, {
          parseOptions: {
            preserveWhitespace: false,
          }
        });
      },
      drop(event) {
      },
      dragover(event) {
        event.preventDefault();
      },
      copyLink() {
        this.$notifications.addNotification({
          type: "success",
          message: "Copied to clipboard !"
        })
        clipboard.copy(window.location.href);
        // navigator.clipboard.writeText(window.location.href);
      },
      toggleFav() {
        this.$store.commit("toggleFavorites", this.file.id);
      },
      downloadAsPDF() {
        window.print()
      },
      downloadAsMarkdown() {
        const turndownService = new TurndownService();
        const markdown = turndownService.turndown(this.editor.getHTML());
        saveAs(
          new Blob([markdown], { type: "text/markdown;charset=utf-8" }),
          `${this.file.id}.md`
        );
      }
    },
  };
</script>
